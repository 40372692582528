/**
 * @author jakubbujakowski
 * @since 2021-2-25
 */

import React from "react";
//@ts-ignore
import ReactFacebookLogin, {
  LoginResponse,
  ProfileSuccessResponse,
} from "@greatsumini/react-facebook-login";
import styled from "styled-components";

import { FACEBOOK_APP_ID } from "@config/consts";
import { LOGIN } from "@config/testIds/login";

import theme from "@ui/themes/default";
import { rem } from "@ui/helpers";
import { FacebookLogo, FacebookIcon } from "@ui/Assets/SocialIcons";
import { Button, TestWrapper } from "@ui/Atoms";

interface RenderProps {
  onClick: () => void;
  disabled?: boolean;
}

/**
 * When using connect-account variant, please remember to pass connectAccountText translation
 */
type Variant = "login" | "connect-account" | "button";

interface ProfileResponse {
  id: string;
  first_name: string;
  last_name: string;
  email?: string;
}

const transformProfileResponse =
  (
    updateProfile: React.Dispatch<React.SetStateAction<ProfileResponse | null>>,
  ) =>
  (resp: ProfileSuccessResponse) => {
    if (!resp?.id) return updateProfile(null);

    const { id, first_name, last_name, email } = resp;
    updateProfile({
      id,
      first_name,
      last_name,
      email,
    });
  };

type SuccessResponse = LoginResponse["authResponse"] & ProfileResponse;
interface Props {
  onSuccess: (resp: SuccessResponse) => void;
  onError: () => void;
  render?: (renderProps: RenderProps) => JSX.Element;
  variant?: Variant;
  translations?: {
    connectAccountText?: string;
    buttonText?: string;
  };
}

const facebookColor = theme.colors.facebook;

const FacebookLogin = (props: Props): React.ReactElement => {
  const [authData, setAuthData] = React.useState<
    LoginResponse["authResponse"] | null
  >(null);
  const [profileData, setProfileData] = React.useState<ProfileResponse | null>(
    null,
  );

  React.useEffect(() => {
    if (authData && profileData) {
      props.onSuccess({
        ...authData,
        ...profileData,
      });
    }
  }, [authData, profileData]);

  const render = (renderProps: RenderProps): React.ReactElement => {
    switch (props.variant) {
      case "login":
        return (
          <TestWrapper testId={LOGIN.FACEBOOK_ICON}>
            <IconInCircle onClick={renderProps.onClick} color={facebookColor}>
              <FacebookLogo color={facebookColor} />
            </IconInCircle>
          </TestWrapper>
        );

      case "connect-account":
        return (
          <React.Fragment>
            <IconSection>
              <IconWrapper socialColor={theme.colors.facebook}>
                <FacebookLogo color="white" size={18} />
              </IconWrapper>
              <span>Facebook</span>
            </IconSection>
            <a
              href="#"
              role="button"
              onClick={(e: React.SyntheticEvent) => {
                e.preventDefault();
                renderProps.onClick();
              }}
            >
              {props.translations?.connectAccountText}
            </a>
          </React.Fragment>
        );

      case "button":
        return (
          <React.Fragment>
            <StyledButton
              type={"button"}
              variant={"borderBlack"}
              size={"fill"}
              onClick={renderProps.onClick}
            >
              <ButtonContentWrapper>
                <FacebookIcon size={32} />
                {props.translations?.buttonText}
              </ButtonContentWrapper>
            </StyledButton>
          </React.Fragment>
        );

      default:
        return <React.Fragment />;
    }
  };

  return (
    <ReactFacebookLogin
      autoLoad={false}
      scope="public_profile, email"
      fields="first_name,last_name,email"
      appId={String(FACEBOOK_APP_ID)}
      onSuccess={setAuthData}
      onProfileSuccess={transformProfileResponse(setProfileData)}
      onFail={props.onError}
      render={props.render ? props.render : render}
    />
  );
};

export { FacebookLogin };

const IconInCircle = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${props => props.theme.margins.base_x4};
  max-width: ${props => props.theme.margins.base_x4};
  min-height: ${props => props.theme.margins.base_x4};
  max-height: ${props => props.theme.margins.base_x4};
  border: 1px solid ${props => props.color};
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: ${props => props.color};

    svg {
      path {
        ${props => `fill: ${props.theme.colors.white}`};
      }
    }
  }
`;

const IconSection = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.span<{ socialColor: string }>`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background: ${props => props.socialColor};
  padding: ${props => props.theme.margins.half};
  width: ${rem(30)};
  height: ${rem(30)};
  margin-right: ${props => props.theme.margins.base_x2};
`;

const StyledButton = styled(Button)`
  text-align: left;
`;

const ButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${rem(22)};

  svg {
    margin-right: ${props => props.theme.margins.base};
  }
`;
